import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import PageIntroBase from '@components/common/page-intro';
import { size, mixin, breakpoint } from '@src/settings';

export const PageIntro = styled( PageIntroBase )`
	margin-top: -4rem;
	margin-bottom: 8rem;
	${ mixin.baseWidthSet }
	position: relative;
	z-index: 1;
	${ media.lessThan(`${ breakpoint.sp }px`)`
		margin-top: 0;
		margin-bottom: ${ size.sectionGap_sp }px;
		width: 100%;
	`}
`
export const Container = styled.div`
	margin-bottom:10rem;
	${ mixin.baseWidthSet }
	${ media.lessThan(`${ breakpoint.sp }px`)`
		margin-bottom: ${ size.sectionGap_sp }px;
	`}
`
