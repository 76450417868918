import React from 'react';
import { Row, Txt, Title, Cap, Img } from './styles';

const FlowItem = props => (
	<Row isFirst={ props.isFirst }>
		<Img fluid={ props.img.childImageSharp.fluid }/>
		<Txt>
			<Title>{ props.title }</Title>
			<Cap>{ props.cap }</Cap>
		</Txt>
	</Row>
)

export default FlowItem;
