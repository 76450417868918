import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery} from 'gatsby';
import Image from 'gatsby-image';

import { color, mixin } from '@src/settings';

import Hero from '@components/common/hero';
import ContactIntro from '@components/common/contact-intro';

import Hero__mds from '@img/svg/flow/hero__mds.svg';

import Layout from '@components/common/layout';
import FlowItem from '@components/flow/flow-item';
import { Container, PageIntro } from '@components/flow/styles';

const FlowPage = ({ data }) => (
	<Layout baseBgColor={ color.paleGray }>
		<Hero
			img={ data["Hero_img_200311"] }
			title={<Hero__mds />}
			subTitle="生産フロー"
		/>

		<PageIntro
			title={<>一貫生産体制でリードタイムが短い生産フロー</>}
			subtitle={<>開発設計から組立（試作、金型・治具製作、溶接、組立、機械加工）までをワンストップで請け負っております。そして、すべての作業を自社の工場の中で行っており、高品質、低価格、短納期が実現できています。一貫生産体制を整えているマレーシア唯一の企業です</>}
		/>
		<Container>
			<FlowItem
				className="flowItem"
				title="解析・SE"
				cap="開発のリードタイムを短縮するため、成形性や工法をシミュレーションでスターディーします。"
				img={ data["Flow01"] }
				isFirst={ true }
			/>

			<FlowItem
				className="flowItem"
				title="試作"
				cap="分析・テスト・評価用の試作部品を製作します。"
				img={ data["Flow03"] }
			/>

			<FlowItem
				className="flowItem"
				title="工程設定"
				cap="量産をする為の工程を設定します。"
				img={ data["Flow03_200311"] }
			/>

			<FlowItem
				className="flowItem"
				title="設備設計"
				cap="ベストな工程計画に基づいた金型設計を行います。"
				img={ data["Flow02"] }
			/>

			<FlowItem
				className="flowItem"
				title="設備製作"
				cap="金型、検具、治具を自社の工場で内製しています。"
				img={ data["Flow05_200311"] }
			/>

			<FlowItem
				className="flowItem"
				title="パレット製作"
				cap="完成品をお客様に良い品質で納めるため、耐久性に優れたカスタムラック等を制作します。"
				img={ data["Flow05"] }
			/>

			<FlowItem
				className="flowItem"
				title="プレス"
				cap="正しいプロセスで高品質の部品を生産します。"
				img={ data["Flow07_200311"] }
			/>

			<FlowItem
				className="flowItem"
				title="アッセンブリ"
				cap="自動化のロボットでAssembly部品を組み立てます。"
				img={ data["Flow07"] }
			/>

			<FlowItem
				className="flowItem"
				title="検査"
				cap="図面に従って、一貫した品質管理を行っています。"
				img={ data["Flow09_200311"] }
			/>

			<FlowItem
				className="flowItem"
				title="デリバリー"
				cap="タイムリーに高品質な部品をお客様に届けるロジスティクスサービス"
				img={ data["Flow09"] }
			/>
		</Container>
		<ContactIntro />
	</Layout>
)

export default FlowPage

export const query = graphql`
	query flowImgs{

		Hero_img_200311: file( relativePath: { eq: "flow/hero-img_200311.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Flow01: file( relativePath: { eq: "flow/flow01.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Flow03: file( relativePath: { eq: "flow/flow03.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Flow03_200311: file( relativePath: { eq: "flow/flow03_200311.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Flow02: file( relativePath: { eq: "flow/flow02.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Flow05_200311: file( relativePath: { eq: "flow/flow05_200311.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Flow05: file( relativePath: { eq: "flow/flow05.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Flow07_200311: file( relativePath: { eq: "flow/flow07_200311.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Flow07: file( relativePath: { eq: "flow/flow07.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Flow09_200311: file( relativePath: { eq: "flow/flow09_200311.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Flow09: file( relativePath: { eq: "flow/flow09.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

	},
`;
