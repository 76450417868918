import React from 'react';
import styled from 'styled-components';
import ImgBase from 'gatsby-image/withIEPolyfill';
import media from 'styled-media-query';
import { color, breakpoint } from '@src/settings';

export const Row = styled.div`
	counter-increment: item;
	display: flex;
	flex-direction: row-reverse;
	position: relative;
  margin-top: 8rem;
	&:before{
		border-bottom: 1rem solid ${color.yellow};
		border-right: 1rem solid ${color.yellow};
		content: "";
		display: block;
		height: 2.6rem;
		left: 50%;
		position: absolute;
		top: -4rem;
		transform: translate( -50%, -70% ) rotate( 45deg );
		width: 2.6rem;
	}
	${ props => props.isFirst && `
		margin-top: 0;
		&:before{
			content: none;
		}	
	`}

	${ media.lessThan(`${ breakpoint.sp }px`)`
		display: block;
	`}
`
export const Txt = styled.div`
		flex: 40rem 0 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		background-color: #FFF;
		color: ${color.blue};
		padding: 5rem;
	${ media.lessThan(`${ breakpoint.sp }px`)`
		padding: 2.5rem;
	`}
`
  
export const Title = styled.h3`
	font-size: 2.2rem;
	font-weight: 500;
	line-height:  1.3;
	margin-bottom: 1.8rem;
		&:before{
			content: counter(item, decimal-leading-zero);
			font-size: 2.4rem;
			line-height: 1.3;
			display: inline-block;
			margin-right: 1.5rem;
		}
  }
	${ media.lessThan(`${ breakpoint.sp }px`)`
		font-size: 2rem;
		margin-bottom: 1rem;
		&:before{
			font-size: 2.2rem;
		}
	`}
` 
export const Cap = styled.p`
		font-size: 1.7rem;
		line-height: 1.7;
`
export const Img = styled(ImgBase)` 
	flex: auto 1 1;
	img{
		height:230px;
	}
	&:before{
		content: "";
		display: block;
	}
`
